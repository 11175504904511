import * as versionJson from './version.json';

export const environment = {
    production: false,
    ApiURL: '',
    idpURL: 'https://auth.dev.otzy.eu',
    env: 'dev',
    version: versionJson.version,
    idpClientId: '755b420d-72ba-4fff-93f1-140f0cb8c0e2',
    googleApisKey: 'AIzaSyB8-JQEoV4ag76aBqDtcVaxslaA4RkH4yM',
};
